import Hubx from "./HubX";

// Ordered alfabetically to facilitate maintenance
export const TEST_FEDX = "TEST_FEDX";

// A Mailbox is a collection of API calls under a commom "mailbox" name.
// This maps requests to MAILBOX_NAME to a set of API calls.
// Ordered alfabetically to facilitate maintenance
export default class Fedx {
  build(hubx: Hubx) {
    hubx.attachMediator(TEST_FEDX, _testFedx);
  }
}

async function _testFedx(): Promise<string> {
  // eslint-disable-next-line no-console
  console.log("testing Fedx...");
  return new Promise((res) => {
    setTimeout(() => {
      res("ok!");
    }, 1000);
  });
}

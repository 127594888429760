import * as React from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-function
function useConstructor(callBack = () => {}) {
  const [hasBeenCalled, setHasBeenCalled] = React.useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

export default useConstructor;

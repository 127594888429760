import { makeStyles, Theme, createStyles, Avatar, AvatarProps } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import clsx from "clsx";
import * as React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      marginLeft: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,

      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
      },
    },

    plainAvatar: {
      width: "auto",
      backgroundColor: "unset",
    },
  })
);

type UserPictureProps = {
  name?: string;
} & AvatarProps;

function UserPicture({ name, ...others }: UserPictureProps) {
  const classes = useStyles();

  return (
    <>
      {name ? (
        <Avatar className={classes.avatar} {...others}>
          {name[0]}
        </Avatar>
      ) : (
        <AccountCircle className={clsx(classes.avatar, classes.plainAvatar)} />
      )}
    </>
  );
}

export default UserPicture;

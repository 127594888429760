// List of events used for communicating between features (notify/attach)
// Ordered alfabetically to facilitate maintenance

import { IEnumerable } from "./core/models/ModelHelpers";

export const EVENT_TEST = "EVENT_TEST";
export const EVENT_TEST_WITH_ID = (id: IEnumerable) => `EVENT_TEST_WITH_ID_${id}`;
export const EVENT_OPEN_DRAWER = "EVENT_OPEN_DRAWER";
export const EVENT_HOMEPAGE_CHANGE_FILTER = "EVENT_HOMEPAGE_CHANGE_FILTER";
export const EVENT_HOMEPAGE_OPEN_ALL = "EVENT_HOMEPAGE_OPEN_ALL";
export const EVENT_HOMEPAGE_CLOSE_ALL = "EVENT_HOMEPAGE_CLOSE_ALL";
export const EVENT_DATEPICKER_CHANGE = (id: IEnumerable) => `EVENT_DATEPICKER_CHANGE_${id}`;

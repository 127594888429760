import * as React from "react";
import MenuItemModel from "../models/MenuItemModel";
import HomeIcon from "@material-ui/icons/Home";
import SettingsIcon from "@material-ui/icons/Settings";
import AssessmentIcon from "@material-ui/icons/Assessment";
// import HelpIcon from "../../components/HelpIcon";
import DocumentIcon from "@material-ui/icons/LibraryBooks";
import SearchIcon from "@material-ui/icons/Search";
import { SessionCache } from "../../helpers/cache";
import User from "../models/User";
import { isArrayValid } from "../../helpers/utility";
import { PERMISSION_ACCESS_ADMIN, PERMISSION_ACCESS_BASE } from "../models/Permission";
import { useHubx } from "../../HubX";
import useConstructor from "./useConstructor";
import get from "lodash/get";
import Liga40Logo from "../../assets/icons/Liga40Logo";

interface UseAppLinks {
  menu: MenuItemModel[];
}

const useAppLinks = (): UseAppLinks => {
  const cache = new SessionCache();

  // const hub = useHubx();

  const menu: MenuItemModel[] = [
    {
      name: "Home",
      route: "/",
      icon: <HomeIcon />,
      permission: PERMISSION_ACCESS_BASE,
      subMenu: [],
    },
    {
      name: "Liga Rio40º",
      route: "/liga_rio_40",
      icon: <Liga40Logo strokeColor="white" fontSize="large" />,
      permission: PERMISSION_ACCESS_BASE,
      subMenu: [],
    },
    {
      name: "Admin",
      route: "/admin/",
      icon: <SettingsIcon />,
      permission: PERMISSION_ACCESS_ADMIN,
      subMenu: [
        {
          name: "Consultar Usuários",
          route: "/search/user",
          icon: <SearchIcon />,
          permission: PERMISSION_ACCESS_ADMIN,
        },
        {
          name: "Consultar Instalação",
          route: "/search/installation",
          icon: <SearchIcon />,
          permission: PERMISSION_ACCESS_ADMIN,
        },
      ],
    },
  ];

  return { menu };
};

export default useAppLinks;

import * as React from "react";
import { Button } from "@material-ui/core";
import { useHubx } from "../../../HubX";
import { EVENT_TEST, EVENT_TEST_WITH_ID } from "../../../Events";

function DemoA() {
  const generateRandom = () => Math.round(Math.random() * 100);

  const [value, changeValue] = React.useState(generateRandom());

  const hub = useHubx();

  React.useEffect(() => {
    const timer = setInterval(() => {
      const nextValue = generateRandom();
      hub.notify(EVENT_TEST, nextValue);
      changeValue(nextValue);
    }, 5 * 1000);

    return () => {
      clearInterval(timer);
    };
  });

  const handleClick = () => {
    hub.notify(EVENT_TEST_WITH_ID(1), value);
  };

  return (
    <div>
      <span>Componente para teste de eventos do Hub A.</span>

      <p>
        <span>Número aleatório: </span>
        <span>{value}</span>
      </p>

      <Button onClick={handleClick}>Forçar update em B</Button>
    </div>
  );
}

export default DemoA;

import React from "react";
import Typography from "@material-ui/core/Typography";
import { Paper, Box, Divider, Button, Card, Container } from "@material-ui/core";
import DemoA from "../../components/Demo/DemoA";
import DemoB from "../../components/Demo/DemoB";
import DemoC from "../../components/Demo/DemoC";
import LegacyDemoC from "../../components/Demo/LegacyDemoC";
import BaseLayout from "../../components/BaseLayout";

function DemoPage() {
  const [showB, setB] = React.useState(true);
  // const classes = useStyles();

  const toggleB = () => {
    setB((prev) => !prev);
  };

  return (
    <BaseLayout>
      <Container maxWidth="xl">
        <Paper>
          <Box>
            <Typography component="h1" variant="h5">
              Bem vindo à Aplicação do Teste do Rio40 Fingerboard!
            </Typography>
          </Box>
          <Divider />
        </Paper>
      </Container>

      <div style={{ display: "flex" }}>
        <Card style={{ flex: 1, margin: 10, padding: 10 }}>
          <DemoA />
        </Card>

        <div style={{ flex: 1, margin: 10 }}>
          {showB && (
            <Card style={{ padding: 10 }}>
              <DemoB />
            </Card>
          )}

          <Button variant="outlined" onClick={() => toggleB()}>
            {showB ? "Matar B!! 🔪💀" : "Reviver B!! 👼"}
          </Button>
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <DemoC />

        <LegacyDemoC />
      </div>
    </BaseLayout>
  );
}

export default DemoPage;

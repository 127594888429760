/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useState } from "react";

declare global {
  interface Window {
    Hubx: Hubx;
  }
}

type IHubX = {
  attach(event: string, observer: Function, withLastValue?: boolean): void;
  detach(event: string, observer: Function): void;
  detachAll(event: string): void;
  detachAllWithPrefix(prefix: string): void;
  attachMediator(address: string, callback: any): void;
  request(address: string, message?: any): Promise<any[]>;
  notify(event: string, message: any): void;
};

// Reference: https://en.wikipedia.org/wiki/Publish-subscribe_pattern;
// Reference: https://en.wikipedia.org/wiki/Mediator_pattern;
export default class Hubx implements IHubX {
  observers: { [key: string]: Function[] };
  buffer: { [key: string]: Function[] };
  mediators: { [key: string]: Function[] };
  isReplaying: boolean;

  constructor() {
    this.observers = {};
    this.buffer = {};
    this.mediators = {};
    this.isReplaying = false;
  }

  /**
   *
   * @param {string} event Nome do evento que será escutado
   * @param {function} observer Função de callback que será chamada
   * @param {boolean} withLastValue Caso seja true durante o attach caso tenha alguma mensagem na fila
   *  a última já é passada para o observer
   */

  attach(event: string, observer: Function, withLastValue = false) {
    if (!this.observers[event]) {
      this.observers[event] = [];
    }
    if (!this.buffer[event]) {
      this.buffer[event] = [];
    }

    if (typeof observer === "undefined") {
      return;
    }

    this.observers[event].push(observer);

    if (withLastValue === true) {
      // Caso já tenha algo na fila já dispara o callback
      if (this.buffer[event] && this.buffer[event].length > 0) {
        setTimeout(() => {
          const last = this.buffer[event].length - 1;
          observer(this.buffer[event][last]);
        }, 1);
      }
    }
  }

  detach(event: string, observer: Function) {
    if (!this.observers[event]) {
      return;
    }
    let index = -1;
    for (let i = 0; i < this.observers[event].length; i++) {
      if (this.observers[event][i] === observer) {
        index = i;
        break;
      }
    }
    if (index >= 0) {
      this.observers[event].splice(index, 1);
    }
  }

  detachAll(event: string) {
    if (!this.observers[event]) {
      return;
    }
    this.observers[event].length = 0;
  }

  detachAllWithPrefix(prefix: string) {
    const events = Object.keys(this.observers).filter((s) => s.startsWith(prefix));
    events.forEach((e: string) => (this.observers[e].length = 0));
  }

  attachMediator(address: string, callback: Function) {
    if (!this.mediators[address]) {
      this.mediators[address] = [];
    }

    this.mediators[address].push(callback);
  }

  request(address: string, message?: any): Promise<any[]> {
    if (!this.mediators[address]) {
      return new Promise((_, rej) => rej("address not registered!"));
    }
    return Promise.all(this.mediators[address].map((x: any) => x(message)));
  }

  notify(event: string, message?: any) {
    if (!this.buffer[event]) {
      this.buffer[event] = [];
    }
    this.buffer[event].pop();
    this.buffer[event].push(message);
    if (!this.observers[event]) {
      return;
    }

    const promises: Promise<any>[] = [];

    this.observers[event].forEach((observer: Function) => {
      promises.push(
        new Promise((res, rej) => {
          setTimeout(function () {
            try {
              observer(message);
              res();
            } catch (e) {
              rej(observer);
            }
          }, 1);
        })
      );
    });
    return Promise.all(promises);
  }
}

export type withHubxType = WithHubxProps;

type WithHubxProps = {
  attach: (event: string, subscriber: Function, withInitialValue?: boolean) => void;
  notify: (event: string, message?: any) => void;
  request: (address: string, message?: any) => Promise<any[] | any>;
  detach: (event: string, subscriber: Function) => void;
};

// HOC + Hooks
export function withHubx<T extends WithHubxProps = WithHubxProps>(
  WrappedComponent: React.ComponentType<T>
) {
  if (!window.Hubx) {
    window.Hubx = new Hubx();
  }

  const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithHubx = (props: Omit<T, keyof WithHubxProps>) => {
    // Fetch the props you want to inject. This could be done with context instead.
    const hubXProps = useHubx();

    // props comes afterwards so the can override the default ones.
    return <WrappedComponent {...hubXProps} {...(props as T)} />;
  };

  ComponentWithHubx.displayName = `withHubx(${displayName})`;

  return ComponentWithHubx;
}

export function legacy_withHubX<T extends WithHubxProps = WithHubxProps>(
  WrappedComponent: React.ComponentType<T>
) {
  if (!window.Hubx) {
    window.Hubx = new Hubx();
  }

  return class legacy_withHubX extends React.Component {
    toDetach: { [key: string]: Function[] };

    constructor(props: T) {
      super(props);
      this.attach = this.attach.bind(this);
      this.notify = this.notify.bind(this);
      this.toDetach = {};
    }

    attach(event: string, subscriber: Function, withInitialValue = false) {
      if (!this.toDetach[event]) {
        this.toDetach[event] = [];
      }
      this.toDetach[event].push(subscriber);
      window.Hubx.attach(event, subscriber, withInitialValue);
    }

    detach(event: string, subscriber: Function) {
      window.Hubx.detach(event, subscriber);
    }

    notify(event: string, message?: any) {
      window.Hubx.notify(event, message);
    }

    async request(address: string, message?: any) {
      try {
        const result = await window.Hubx.request(address, message);

        if (result && result.length === 1) {
          return result[0];
        }

        return result;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(`request -> ${address} -> error`, error);
      }
    }

    componentWillUnmount() {
      Object.keys(this.toDetach).map((event) => {
        return this.toDetach[event].forEach((e: any) => {
          window.Hubx.detach(event, e);
        });
      });
    }

    render() {
      const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";

      WrappedComponent.displayName = `withHubx(${displayName})`;

      return (
        <WrappedComponent
          {...(this.props as T)}
          attach={this.attach}
          notify={this.notify}
          request={this.request}
        />
      );
    }
  };
}

// HOOKs
export const useHubx = (): withHubxType => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);

  if (!window.Hubx) {
    window.Hubx = new Hubx();
  }

  const attach = (event: string, subscriber: Function, withInitialValue = false) => {
    if (withInitialValue && !hasBeenCalled) {
      window.Hubx.attach(event, subscriber, true);
      setHasBeenCalled(true);
    } else {
      window.Hubx.attach(event, subscriber);
    }

    return () => detach(event, subscriber);
  };

  const detach = (event: string, subscriber: Function) => {
    return window.Hubx.detach(event, subscriber);
  };

  const notify = (event: string, message?: any) => {
    window.Hubx.notify(event, message);
  };

  const request = async (address: string, message?: any): Promise<any[] | any> => {
    try {
      const result = await window.Hubx.request(address, message);

      if (result && result.length === 1) {
        return result[0];
      }

      return result;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`request -> ${address} -> error`, error);
      return Promise.reject(error);
    }
  };

  return { attach, request, notify, detach };
};

export const useHubXSubscribe = (event: string, subscriber: Function, withInitialValue = false) => {
  const hub = useHubx();

  React.useEffect(() => {
    hub.attach(event, subscriber, withInitialValue);
    return () => hub.detach(event, subscriber);
  });
};

import * as React from "react";
import { makeStyles, SvgIcon, SvgIconProps, Theme } from "@material-ui/core";

type styleProps = {
  color?: React.CSSProperties["color"];
};

const useStyles = makeStyles<Theme, styleProps>((theme: Theme) => ({
  root: ({ color }) => ({
    "& .st0": { fill: "none", stroke: color || "#000000", strokeWidth: 20, strokeMiterlimit: 10 },
    "& .st1": { fill: "none", stroke: color || "#000000", strokeWidth: 19, strokeMiterlimit: 10 },
    "& .st2": { fill: "none", stroke: color || "#000000", strokeWidth: 10, strokeMiterlimit: 10 },
    "& .st3": { fill: "none", stroke: color || "#000000", strokeMiterlimit: 10 },
    "& .st4": { fill: "none", stroke: color || "#000000", strokeWidth: 14, strokeMiterlimit: 10 },
    "& .st5": { fill: "none", stroke: color || "#000000", strokeWidth: 6, strokeMiterlimit: 10 },
  }),
}));

type Props = SvgIconProps & { strokeColor?: React.CSSProperties["color"] };

function Rio40Logo({ strokeColor, ...props }: Props) {
  const classes = useStyles({ color: strokeColor });

  return (
    <SvgIcon className={classes.root} {...props} viewBox="470 125 500 425">
      <line className="st0" x1="675" y1="143" x2="495" y2="323" />
      <path className="st0" d="M498,321c59,1,118-1,177.4-0.8" />
      <line className="st0" x1="675.4" y1="233" x2="675.4" y2="445" />
      <g id="XMLID_1_">
        <g></g>
        <g>
          <circle className="st0" cx="818.8" cy="320.3" r="86" />
          <line className="st1" x1="732.8" y1="320.3" x2="732.8" y2="540" />
        </g>
      </g>
      <circle className="st2" cx="939.3" cy="240.3" r="13.3" />
      <path className="st3" d="M488,316.3" />
      <line className="st0" x1="818.8" y1="406.3" x2="952.5" y2="540" />
      <line className="st4" x1="498.2" y1="380" x2="675.2" y2="482.3" />
      <circle className="st4" cx="529.8" cy="435.8" r="11.8" />
      <circle className="st4" cx="615.5" cy="484.5" r="11.8" />
      <ellipse className="st5" cx="498.8" cy="321.6" rx="10.5" ry="6.4" />
      <ellipse className="st5" cx="497.1" cy="321.6" rx="4.4" ry="3.3" />
    </SvgIcon>
  );
}

export default Rio40Logo;

import findKey from "lodash//findKey";

export function isArrayValid(inputTest: unknown): boolean {
  return inputTest && Array.isArray(inputTest) && inputTest.length > 0;
}

export function formatDateTime(stringDate: string): string {
  const options = {
    hour: "2-digit",
    minute: "2-digit",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  const date = new Date(stringDate).toLocaleString("default", options);
  return `${date.split(" ")[1]} ${date.split(" ")[0]}`;
}
export function encodeStringToBase64(text: string): string {
  return new Buffer(text).toString("base64");
}

type keyValuePair = Record<string | number, unknown>[];

export function getKeyFromValue(value: string | number, items: keyValuePair) {
  return findKey(items, value);
}

export class SessionCache {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set(key: string, obj: any): void {
    sessionStorage.setItem(key, JSON.stringify(obj));
  }

  get<T>(key: string): T | null {
    const item = sessionStorage.getItem(key);

    if (!item) {
      return null;
    }

    return JSON.parse(item);
  }

  exists(key: string): boolean {
    return !!sessionStorage.getItem(key);
  }

  delete<T>(key: string): void {
    sessionStorage.removeItem(key);
  }

  clear(): void {
    sessionStorage.clear();
  }
}

export class LocalCache {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set(key: string, obj: any): void {
    localStorage.setItem(key, JSON.stringify(obj));
  }

  get<T>(key: string): T | null {
    const item = localStorage.getItem(key);

    if (!item) {
      return null;
    }

    return JSON.parse(item);
  }

  exists(key: string): boolean {
    return !!localStorage.getItem(key);
  }
}

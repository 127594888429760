/* eslint-disable @typescript-eslint/no-var-requires */
import * as React from "react";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
  Zoom,
  CssBaseline,
} from "@material-ui/core";
import { LocalCache } from "./helpers/cache";

// Allow changes to the Material global Theme object;
declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    customPalette: {
      text: {
        primary: React.CSSProperties["color"];
        darkgrey: React.CSSProperties["color"];
        link: React.CSSProperties["color"];
        linkHover: React.CSSProperties["color"];
        linkContrast: React.CSSProperties["color"];
        linkHoverContrast: React.CSSProperties["color"];
        error: React.CSSProperties["color"];
        focusColor: React.CSSProperties["color"];
      };
      svg: {
        lightgrey: React.CSSProperties["color"];
      };
      border: {
        gray: React.CSSProperties["color"];
      };
      levels: {
        veryLow: React.CSSProperties["color"];
        low: React.CSSProperties["color"];
        normal: React.CSSProperties["color"];
        high: React.CSSProperties["color"];
      };
      misc: {
        focusColor: React.CSSProperties["color"];
      };
    };
    customPaper: {
      background: {
        cardBackgroundLight: React.CSSProperties["color"];
        cardBackgroundLightVariant: React.CSSProperties["color"];
        cardBackground: React.CSSProperties["color"];
        cardBackgroundSelected: React.CSSProperties["color"];
      };
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    customPalette?: {
      text?: {
        primary?: React.CSSProperties["color"];
        darkgrey?: React.CSSProperties["color"];
        link?: React.CSSProperties["color"];
        linkHover?: React.CSSProperties["color"];
        linkContrast: React.CSSProperties["color"];
        linkHoverContrast: React.CSSProperties["color"];
        error: React.CSSProperties["color"];
      };
      svg?: {
        lightgrey?: React.CSSProperties["color"];
      };
      border?: {
        gray?: React.CSSProperties["color"];
      };
      levels?: {
        veryLow?: React.CSSProperties["color"];
        low?: React.CSSProperties["color"];
        normal?: React.CSSProperties["color"];
        high?: React.CSSProperties["color"];
      };
      misc?: {
        focusColor?: React.CSSProperties["color"];
      };
    };
    customPaper?: {
      background?: {
        cardBackgroundLight?: React.CSSProperties["color"];
        cardBackgroundLightVariant?: React.CSSProperties["color"];
        cardBackground?: React.CSSProperties["color"];
        cardBackgroundSelected?: React.CSSProperties["color"];
      };
    };
  }
}

/** * Theme customization start ***/

// Theme Props
type Props = {
  children?: React.ReactChild;
};

// shared values;
const APP_SECONDARY_COLOR = "#add99d";
const APP_PRIMARY_COLOR = "#c965c9";
const APP_FOCUS_COLOR = "#E89793";
const linkColor = "#00A0E1";
const linkHoverColor = "#007AB9";
const appWhite = "#FFFFFF";
const appError = "#ED3E6C";
const appWarning = "#FBD249";
const selectBorder = "1px solid #B9CDD7";

function AppThemeProvider(props: Props) {
  const lcache = new LocalCache();
  const prefersDarkMode = lcache.get<boolean>("rio40.prefers.dark.mode");

  const defaultTheme = React.useMemo(() => createMuiTheme(), []);

  let AppTheme = React.useMemo(
    () =>
      createMuiTheme({
        typography: {
          fontFamily: ["Roboto", "Bebas Neue", "Arial", "-apple-system", "sans-serif"].join(","),
          h1: {
            fontFamily: ["Bebas Neue", "Roboto", "Arial", "-apple-system", "sans-serif"].join(","),
          },
          h2: {
            fontFamily: ["Bebas Neue", "Roboto", "Arial", "-apple-system", "sans-serif"].join(","),
          },
          h3: {
            fontFamily: ["Bebas Neue", "Roboto", "Arial", "-apple-system", "sans-serif"].join(","),
          },
          h4: {
            fontFamily: ["Bebas Neue", "Roboto", "Arial", "-apple-system", "sans-serif"].join(","),
          },
          h5: {
            fontFamily: ["Bebas Neue", "Roboto", "Arial", "-apple-system", "sans-serif"].join(","),
          },
          h6: {
            fontFamily: ["Bebas Neue", "Roboto", "Arial", "-apple-system", "sans-serif"].join(","),
          },
        },
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          primary: {
            main: APP_PRIMARY_COLOR,
          },
          secondary: {
            main: APP_SECONDARY_COLOR,
          },
          error: {
            main: "#E1000F",
          },
          text: {
            secondary: "#717171",
          },
        },
        customPaper: {
          background: {
            cardBackground: "#6E7878",
            cardBackgroundSelected: "#B6BBBB",
            cardBackgroundLight: "#E7E7E7",
            cardBackgroundLightVariant: "#D7DAE2",
          },
        },
        customPalette: {
          text: {
            primary: "#6E7878",
            darkgrey: "#4D4F5C",
            link: linkColor,
            linkHover: linkHoverColor,
            linkContrast: appWhite,
            linkHoverContrast: "#B3CCDC",
            error: appError,
          },
          border: {
            gray: "#707070",
          },
          svg: {
            lightgrey: "#BCBCCB",
          },
          levels: {
            veryLow: appError,
            low: appWarning,
            normal: "#B3E1C4",
            high: "#6A6A6A",
          },
          misc: {
            focusColor: APP_FOCUS_COLOR,
          },
        },

        /** MATERIAL GLOBAL OVERRIDES**/
        overrides: {
          // custom Fonts
          MuiCssBaseline: {
            "@global": {
              body: {
                backgroundColor: "#F5F5F5",
              },
            },
          },

          MuiFormControl: {
            root: {
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: APP_PRIMARY_COLOR,
              },

              "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                background: appWhite,
                borderTop: selectBorder,
              },
            },
          },

          // Native select
          // MuiSelect: {
          //   select: {
          //     "&:focus": {
          //       backgroundColor: appWhite,
          //     },
          //   },
          // },

          MuiOutlinedInput: {
            root: {
              "borderRadius": "unset",

              "&:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: APP_PRIMARY_COLOR,
                },
              },
            },
          },

          MuiLink: {
            button: {
              "color": linkColor,

              "&:hover": {
                color: linkHoverColor,
              },
            },
          },

          MuiPopover: {
            root: {
              "&.MuiPaper-root .MuiMenu-paper .MuiPopover-paper .MuiPaper-elevation8 .MuiPaper-rounded": {
                marginTop: defaultTheme.spacing(5.5),
              },
            },
            paper: {
              "&.MuiPaper-root .MuiMenu-paper .MuiPopover-paper .MuiPaper-elevation8 .MuiPaper-rounded": {
                marginTop: defaultTheme.spacing(5.5),
              },
            },
          },

          MuiTooltip: {
            tooltip: {
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "1em",
              backgroundColor: appWhite,
              borderRadius: "unset",
              border: selectBorder,
              boxShadow: defaultTheme.shadows[1],
            },
          },

          MuiCard: {
            root: {
              borderRadius: 0,
            },
          },

          MuiButton: {
            root: {
              borderRadius: 0,
            },
          },

          MuiIconButton: {
            root: {
              color: "#717171",
            },
          },

          MuiPickerDTTabs: {
            tabs: {
              color: defaultTheme.palette.common.white,
            },
          },

          MuiPickersToolbarText: {
            toolbarTxt: {
              color: "rgba(255,255,255, 0.54)",
            },
            toolbarBtnSelected: {
              color: "rgba(255,255,255, 0.87)",
            },
          },
        },

        /* MATERIAL GLOBAL DEFAULT PROPS OVERRIDES */
        props: {
          MuiTooltip: {
            placement: "right-start",
            TransitionComponent: Zoom,
            arrow: true,
          },
        },
      }),
    [defaultTheme, prefersDarkMode]
  );

  AppTheme = responsiveFontSizes(AppTheme);

  return (
    <ThemeProvider theme={AppTheme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
}

export default AppThemeProvider;

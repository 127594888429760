import * as React from "react";
import { useHubx, useHubXSubscribe } from "../../../HubX";
import { EVENT_TEST, EVENT_TEST_WITH_ID } from "../../../Events";
import useConstructor from "../../../core/hooks/useConstructor";
import { NullableString } from "../../../core/models/ModelHelpers";
import { TEST_FEDX } from "../../../Fedx";

function DemoB() {
  const hub = useHubx();
  const [value, changeValue] = React.useState(0);
  const [value2, changeValue2] = React.useState(0);
  const [textTest, changeTextTest] = React.useState<NullableString>(null);

  useHubXSubscribe(EVENT_TEST, changeValue, true);
  useHubXSubscribe(EVENT_TEST_WITH_ID(1), changeValue2);

  // Request ao construir
  useConstructor(async () => {
    const test = await hub.request(TEST_FEDX);
    changeTextTest(test);
  });

  return (
    <div>
      <span>Componente para teste de eventos do Hub B.</span>

      <p>
        <span>Número aleatório de A: </span>
        <span>{value}</span>
      </p>

      <p>
        <span>Número Forçado de A: </span>
        <span>{value2}</span>
      </p>

      {textTest && <p>Request pelo Fedex: {textTest}</p>}
    </div>
  );
}

export default DemoB;

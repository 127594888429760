import { Card, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import * as React from "react";
import User from "../../../core/models/User";
import { SessionCache } from "../../../helpers/cache";
import UserPicture from "../../UserPicture";
import backgroundImage from "../../../assets/images/wm_back.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundImage: `url(${backgroundImage})`,
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      position: "relative",
      backgroundSize: "cover",
    },

    card: {
      "background": theme.customPaper.background.cardBackground,
      "maxHeight": 100,

      "& h4": {
        color: theme.palette.common.white,
        padding: theme.spacing(6, 2, 1, 2),
      },

      // avatar override
      "& > div": {
        position: "absolute",
        fontSize: 60,
        width: 80,
        height: 80,
        top: "30%",
        left: 20,
        backgroundColor: "#bdbdbd",
      },
    },
  })
);

function MobileDrawerHeader() {
  const name = React.useMemo(() => {
    const cache = new SessionCache();
    const cachedUser: User | null = cache.get("user");

    return cachedUser?.name || "Usuário";
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <UserPicture name={name} />

        <Typography variant="h4" component="h4">
          {name}
        </Typography>
      </Card>
    </div>
  );
}

export default MobileDrawerHeader;

import React from "react";
import { SnackbarProvider } from "notistack";
import Routes from "./Routes";
import APPThemeProvider from "./AppThemeProvider";

function App() {
  return (
    <APPThemeProvider>
      <SnackbarProvider>
        <Routes />
      </SnackbarProvider>
    </APPThemeProvider>
  );
}

export default App;

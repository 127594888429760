import * as React from "react";
import {
  Avatar,
  Typography,
  Button,
  Box,
  MenuItem,
  Theme,
  makeStyles,
  createStyles,
  Menu,
  Divider,
  Hidden,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { SessionCache } from "../../helpers/cache";
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import clsx from "clsx";

type Props = { name?: string };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRoot: {
      "height": theme.mixins.toolbar.minHeight,

      "& .MuiButton-label": {
        "height": "100%",

        "&  .avatar": {
          height: "100%",
          marginLeft: theme.spacing(1),
          backgroundColor: theme.palette.primary.main,
        },

        "& .plainAvatar": {
          width: "auto",
          backgroundColor: "unset",
        },
      },

      "& .nameWrapper": {
        "display": "flex",
        "alignItems": "center",
        "marginRight": theme.spacing(1),

        "& .divider": {
          height: "2em",
          marginRight: theme.spacing(2),
        },
      },
    },
  })
);

function UserMenu(props: Props) {
  const { name } = props;

  const classes = useStyles();

  const lCache = new SessionCache();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickLogout = () => {
    lCache.set("bearer-token", null);
    // Redirect to initial page
    history.push("/");
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        className={classes.buttonRoot}
        onClick={handleToggle}
        aria-label="Menu do Usuário"
        aria-haspopup="true"
      >
        <Box className={"nameWrapper"}>
          <Divider className={"divider"} orientation="vertical" />
          <Hidden xsDown>
            <Typography variant="body1">{name ? name.split(" ")[0] : "Usuário"}</Typography>
          </Hidden>
        </Box>

        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}

        {name ? (
          <Avatar className="avatar">{name[0]}</Avatar>
        ) : (
          <AccountCircle className={clsx("avatar", "plainAvatar")} />
        )}
      </Button>
      <Menu
        id="menu-appbar"
        keepMounted
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem onClick={handleClickLogout}>Log Out</MenuItem>
      </Menu>
    </>
  );
}

export default UserMenu;

import * as React from "react";
import { withHubx, withHubxType } from "../../../HubX";
import { TEST_FEDX } from "../../../Fedx";
import { EVENT_TEST } from "../../../Events";
import { Card } from "@material-ui/core";

type DemoCState = { value: number; fedexTest?: string };
type DemoCProps = withHubxType;

class DemoC extends React.Component<DemoCProps, DemoCState> {
  constructor(props: DemoCProps) {
    super(props);
    this.state = {
      value: 0,
    };

    this.testFedex = this.testFedex.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.handleTestResponse = this.handleTestResponse.bind(this);

    props.attach(EVENT_TEST, this.updateValue, true);
  }

  componentDidMount() {
    this.testFedex();
  }

  updateValue(value: number) {
    this.setState({ value });
  }

  async testFedex() {
    const response = await this.props.request(TEST_FEDX);
    this.handleTestResponse(response);
  }

  handleTestResponse(APIResponse: string) {
    this.setState({ fedexTest: APIResponse });
  }

  render() {
    const { value, fedexTest } = this.state;

    return (
      <Card style={{ margin: 10, padding: 10, width: "50%" }}>
        <span>Componente Classe para teste de eventos do Hub C.</span>

        <p>
          <span>Número aleatório de A: </span>
          <span>{value}</span>
        </p>

        {fedexTest && <p>Request pelo Fedex: {fedexTest}</p>}
      </Card>
    );
  }
}

export default withHubx(DemoC);

import { APP_IS_RUNNING_IN_DEV } from "../core/constants/appGlobals";

/* eslint-disable no-console */
declare global {
  interface Window {
    SHOW_WARN_MESSAGE: boolean;
  }
}

function logOverwrite() {
  // Console.log override
  window.SHOW_WARN_MESSAGE = true;
  window.console.log = APP_IS_RUNNING_IN_DEV
    ? console.log
    : () => {
        if (window.SHOW_WARN_MESSAGE) {
          console.warn(`Debug is OFF for env: ${process.env.NODE_ENV}`);
          window.SHOW_WARN_MESSAGE = false;
        }
      };
}

export default logOverwrite;

import { useTheme, useMediaQuery } from "@material-ui/core";
import { includes, uniqBy } from "lodash";
import * as React from "react";
import { SessionCache } from "../../helpers/cache";
import { isArrayValid } from "../../helpers/utility";
import { useHubx } from "../../HubX";
import { BLOCK_FEATURE_ON_MOBILE_PERMISSIONS } from "../constants/blockedMobileFeatures";
import { APP_PERMISSIONS, PermissionsType } from "../models/Permission";
import User from "../models/User";

type useAuthType = {
  set: (token: string | null) => void;
  get: () => boolean;
  hasPermission: (permissionType: PermissionsType) => boolean;
  hasAnyPermission: (permissionToCheck: PermissionsType[]) => boolean;
  mobileRestriction: (permissionToCheck: PermissionsType[]) => boolean;
};

const useAuth = (): useAuthType => {
  const cache = new SessionCache();
  const initialAuth = cache.get("bearer-token") ? true : false;
  const storedUser: User | null = cache.get("user");
  const hub = useHubx();
  const theme = useTheme();
  const isMobileResolution = useMediaQuery(theme.breakpoints.down("xs"));

  React.useEffect(() => {
    // const fetchUser = async () => {
    //   try {
    //     const UserFromAPI = await hub.request(FETCH_USER_INFO);
    //     cache.set("user", UserFromAPI);
    //   } catch (error) {
    //     // eslint-disable-next-line no-console
    //     console.error("useAuth → fetchUser -> error", error);
    //   }
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedUser]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setAuth] = React.useState(initialAuth);

  const set = (token: string | null) => {
    cache.set("bearer-token", token);
    setAuth(Boolean(token));
  };

  const get = () => {
    return cache.get("bearer-token") ? true : false;
  };

  const getPermissions = React.useCallback(() => {
    const isSuperAdmin = storedUser?.email === "admin@admin.com.br";

    if (isSuperAdmin) {
      return APP_PERMISSIONS.map((x) => {
        return { type: x };
      });
    }

    return [];
  }, [storedUser]);

  const hasPermission = (permissionType: PermissionsType) => {
    return true;
    if (permissionType === 0) {
      return get();
    }

    const permissions = getPermissions();

    return includes(
      permissions.map((x) => x?.type),
      permissionType
    );
  };

  const hasAnyPermission = (permissionToCheck: PermissionsType[]) => {
    return true;
    const permissions = getPermissions();
    const permissionsTypes = permissions?.map((x) => x.type);

    return permissionToCheck.some((permission) => permissionsTypes.includes(permission));
  };

  const mobileRestriction = (permissionToCheck: PermissionsType[]) => {
    if (!isMobileResolution) {
      return false;
    }

    return permissionToCheck.every((permission) =>
      BLOCK_FEATURE_ON_MOBILE_PERMISSIONS.includes(permission)
    );
  };

  return { get, set, hasPermission, hasAnyPermission, mobileRestriction };
};

export default useAuth;

import * as React from "react";
import {
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  Tooltip,
  ListItemText,
  createStyles,
  makeStyles,
  Theme,
  SwipeableDrawer,
  Hidden,
  Button,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuItemModel from "../../core/models/MenuItemModel";

import { matchPath, useHistory, useLocation, useParams } from "react-router-dom";
import useAppLinks from "../../core/hooks/useAppLinks";
import clsx from "clsx";
import { SessionCache } from "../../helpers/cache";
import { useHubXSubscribe } from "../../HubX";
import { EVENT_OPEN_DRAWER } from "../../Events";
import get from "lodash/get";
import Rio40Logo from "../../assets/icons/Rio40Logo";
import ProtectedRender from "../../features/ProtectedRender";
import MobileDrawerHeader from "./MobileDrawerHeader";

type Props = Record<string, unknown>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiDrawer-paperAnchorDockedLeft": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        background: theme.customPaper.background.cardBackground,
      },

      "& svg": {
        color: theme.palette.common.white,
      },
    },

    linkIcons: {
      "& .MuiListItemIcon-root": {
        minWidth: 0,
        paddingLeft: theme.spacing(0.25),
      },
    },

    appIcon: {
      "padding": 0,
      "borderBottom": `1px solid ${theme.palette.common.white}`,
      "backgroundColor": theme.customPalette.misc.focusColor,
      "& svg": {
        width: "2em",
        height: "2em",
        margin: "auto",
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
      },
    },

    toggleButton: {
      "paddingBottom": theme.spacing(2),

      "& .MuiIconButton-root": {
        "background": theme.palette.common.white,
        "padding": 5,

        "& svg": { color: theme.customPaper.background.cardBackground },
      },
    },

    // App drawer 'swipeable'
    rootTemp: {
      "& .MuiDrawer-paper": {
        "minWidth": 350,

        "background": theme.customPaper.background.cardBackground,
        "color": theme.palette.common.white,
        "display": "flex",
        "flexDirection": "column",
        "justifyContent": "space-between",

        [theme.breakpoints.down("xs")]: {
          minWidth: "80%",
        },

        // Ícones SVG
        "& svg": {
          color: theme.palette.common.white,
        },

        // Lista de ícones
        "& .MuiList-root": {
          marginTop: theme.spacing(2),
        },

        "& .MuiListItem-root": {
          "borderBottom": `0.4px solid ${theme.palette.common.white}`,

          "&:hover": {
            background: theme.customPaper.background.cardBackgroundSelected,
          },
        },

        // Logo component overwrites
        "& .MuiButton-root": {
          "width": "100%",
          "&:hover": {
            "background": "rgba(255, 255, 255, 0.96)",
            "& *": {
              fill: theme.palette.primary.main,
              color: theme.palette.primary.main,
            },
          },
          [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(1.5, 0, 1.5, 0),
          },
          [theme.breakpoints.down("sm")]: {
            "padding": theme.spacing(1, 0, 1, 0),
            "& svg": {
              width: "10em",
            },
          },
        },
      },
    },

    selectedListItem: {
      background: theme.customPaper.background.cardBackgroundSelected,
      borderLeft: `4px solid ${theme.palette.common.white}`,
    },

    tempToggleButton: {
      "padding": theme.spacing(0, 2, 2, 0),
      "display": "flex",
      "justifyContent": "flex-end",

      [theme.breakpoints.down("xs")]: {
        justifyContent: "space-between",
      },

      "& .MuiIconButton-root": {
        "background": theme.palette.common.white,
        "padding": 5,

        "& svg": { color: theme.customPaper.background.cardBackground },

        [theme.breakpoints.down("xs")]: {
          flex: 1,
        },
      },
    },

    subMenu: {
      margin: theme.spacing(2),
    },

    logoutButton: {
      color: "white",
      fontWeight: "bold",
      width: "min-content !important",
      marginLeft: theme.spacing(1),
    },

    mobileMenusWrapper: {
      flex: 2.5,
    },

    permanentList: {
      "&.MuiList-padding": {
        paddingTop: 0,
        marginTop: theme.spacing(-1),
      },
    },
  })
);

function AppDrawer(props: Props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const cache = new SessionCache();

  const theme = useTheme();
  const isMobileScreenSize = useMediaQuery(theme.breakpoints.down("xs"));

  const [open, setOpen] = React.useState(cache.get("drawer-state") || false);

  const handleClick = (route: string) => {
    if (isMobileScreenSize) {
      cache.set("drawer-state", false);
      setOpen(false);
    }
    history.push(route);
  };

  const logOut = () => {
    cache.clear();
    // Redirect to initial page
    history.push("/");
  };

  const isSelected = React.useCallback(
    (route: string, subMenus?: MenuItemModel[]): boolean => {
      return location.pathname === route || Boolean(subMenus?.find((x) => isSelected(x.route)));
    },
    [location.pathname]
  );

  const toggleOpenDrawer = (toggle: boolean) => {
    cache.set("drawer-state", toggle);
    setOpen(toggle);
  };

  useHubXSubscribe(EVENT_OPEN_DRAWER, () => toggleOpenDrawer(true));

  const pages = useAppLinks()?.menu ?? [];
  const iOS = Boolean(process.env.browser && /iPad|iPhone|iPod/.test(navigator.userAgent));

  const renderSubMenu = React.useCallback(
    (app: MenuItemModel, isSubMenu = false): React.ReactNode => {
      return (
        <ProtectedRender permission={app.permission} key={`menu-${app.name}`}>
          <ListItem
            className={clsx(
              isSelected(app.route, app.subMenu) && classes.selectedListItem,
              isSubMenu && classes.subMenu
            )}
            button
            onClick={() => handleClick(app.route)}
          >
            <ListItemIcon>{app.icon}</ListItemIcon>
            <ListItemText>{app.name}</ListItemText>
          </ListItem>
          {app.subMenu?.map((x) => renderSubMenu(x, true))}
        </ProtectedRender>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleClick, isSelected]
  );

  return (
    <>
      {/* Permanent Drawer */}
      {
        <Hidden xsDown>
          <Drawer className={classes.root} variant="permanent">
            <div>
              <List className={classes.permanentList}>
                <Tooltip title="Abrir Menu">
                  <ListItem
                    button
                    className={clsx(classes.linkIcons, classes.appIcon)}
                    onClick={() => toggleOpenDrawer(true)}
                  >
                    <Rio40Logo strokeColor="white" />
                  </ListItem>
                </Tooltip>

                {pages.map((app: MenuItemModel, idx: number) => (
                  <ProtectedRender key={idx} permission={app.permission}>
                    <Tooltip key={idx} title={app.name}>
                      <ListItem
                        className={clsx(
                          classes.linkIcons,
                          isSelected(app.route, app.subMenu) && classes.selectedListItem
                        )}
                        button
                        onClick={() => handleClick(app.route)}
                      >
                        <ListItemIcon>{app.icon}</ListItemIcon>
                      </ListItem>
                    </Tooltip>
                  </ProtectedRender>
                ))}
              </List>
            </div>

            <div className={classes.toggleButton}>
              <Tooltip title="Abrir Menu">
                <IconButton onClick={() => toggleOpenDrawer(true)}>
                  <ChevronRightIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Drawer>
        </Hidden>
      }

      {/* Temp Drawer */}
      {/* Mobile */}
      <Hidden smUp>
        <SwipeableDrawer
          className={classes.rootTemp}
          anchor="left"
          open={open}
          onOpen={() => toggleOpenDrawer(true)}
          onClose={() => toggleOpenDrawer(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
        >
          <MobileDrawerHeader />

          <div className={classes.mobileMenusWrapper}>
            <List>{pages.map((app: MenuItemModel) => renderSubMenu(app))}</List>
          </div>

          <div className={classes.tempToggleButton}>
            <Button className={classes.logoutButton} variant="text" onClick={() => logOut()}>
              Log Out
            </Button>

            <div>
              <Tooltip title="Fechar Menu">
                <IconButton onClick={() => toggleOpenDrawer(false)}>
                  <ChevronLeftIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </SwipeableDrawer>
      </Hidden>

      {/* Desktop */}
      <Hidden xsDown>
        <SwipeableDrawer
          className={classes.rootTemp}
          anchor="left"
          open={open}
          onOpen={() => toggleOpenDrawer(true)}
          onClose={() => toggleOpenDrawer(false)}
        >
          <div>
            <List>{pages.map((app: MenuItemModel) => renderSubMenu(app))}</List>
          </div>

          <div className={classes.tempToggleButton}>
            <Tooltip title="Fechar Menu">
              <IconButton onClick={() => toggleOpenDrawer(false)}>
                <ChevronLeftIcon />
              </IconButton>
            </Tooltip>
          </div>
        </SwipeableDrawer>
      </Hidden>
    </>
  );
}

export default AppDrawer;

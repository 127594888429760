import * as React from "react";
import { Container, Box, makeStyles, Theme } from "@material-ui/core";
import Header from "../Header";
import AppDrawer from "../Drawer";

type Props = {
  paddingTop?: boolean;
  children: React.ReactNode;
};

type styleProps = {
  topPadding: boolean;
};

const useStyle = makeStyles<Theme, styleProps>((theme: Theme) => ({
  root: ({ topPadding }) => ({
    marginTop: 64,
    backgroundColor: "#F5F5F5",

    [theme.breakpoints.up("sm")]: {
      paddingTop: topPadding ? theme.spacing(5) : "unset",
      paddingLeft: theme.spacing(9),
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: topPadding ? theme.spacing(2) : "unset",
    },
  }),
}));

function BaseLayout({ children, paddingTop = false }: Props) {
  const classes = useStyle({ topPadding: paddingTop });

  return (
    <Container className={classes.root} component="main" maxWidth="xl">
      <Header />
      <AppDrawer />
      <Box>{children}</Box>
    </Container>
  );
}

export default BaseLayout;

import * as React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Theme,
  makeStyles,
  createStyles,
  Box,
  Hidden,
  IconButton,
} from "@material-ui/core";
import UserMenu from "../UserMenu";
import { SessionCache } from "../../helpers/cache";
import IUserResponse from "../../core/contracts/IUserResponse";
import MenuIcon from "@material-ui/icons/Menu";
import { useHubx } from "../../HubX";
import { EVENT_OPEN_DRAWER } from "../../Events";

type Props = {};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
    },

    menuWrapper: {
      "display": "flex",
      "flex": 1,
      "justifyContent": "space-between",
      "alignItems": "center",

      "& h4": {
        [theme.breakpoints.up("sm")]: {
          paddingLeft: theme.spacing(12),
        },
        [theme.breakpoints.down("sm")]: {
          paddingLeft: theme.spacing(1),
        },
        fontWeight: "bold",
      },
    },

    iconsWrapper: {
      "display": "flex",

      "& .menu-icon": {
        flex: 1,
      },
    },
  })
);

function Header(props: Props) {
  const classes = useStyles();
  const hub = useHubx();

  const [userName, setUserName] = React.useState<string>("");

  React.useEffect(() => {
    const sessionCache = new SessionCache();
    const userData = sessionCache.get<IUserResponse>("user");
    if (userData) {
      setUserName(userData.name);
    }
  }, []);

  const handleClick = () => {
    hub.notify(EVENT_OPEN_DRAWER);
  };

  return (
    <>
      <AppBar className={classes.root}>
        <Toolbar>
          <div className={classes.iconsWrapper}>
            <Hidden smUp>
              <IconButton className="menu-icon" onClick={handleClick}>
                <MenuIcon />
              </IconButton>
            </Hidden>
          </div>

          <Box className={classes.menuWrapper}>
            <Typography variant="h4" color="textSecondary">
              Rio40 Fingerboard
            </Typography>

            <UserMenu name={userName} />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Header;

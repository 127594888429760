import * as React from "react";
import { makeStyles, SvgIcon, SvgIconProps, Theme } from "@material-ui/core";

type styleProps = {
  color?: React.CSSProperties["color"];
};

const useStyles = makeStyles<Theme, styleProps>((theme: Theme) => ({
  root: ({ color }) => ({
    "paddingRight": theme.spacing(1),
    "& .a": { fill: color || "#211915" },
    "& .b": {
      fill: "none",
      stroke: color || "#211915",
      strokeMiterlimit: 10,
      strokeWidth: 16,
    },
    "& .c": {
      fill: "none",
      stroke: color || "#211915",
      strokeMiterlimit: 10,
      strokeWidth: 6,
    },
    "& .d": {
      fill: "none",
      stroke: color || "#211915",
      strokeWidth: 6,
      strokeLinecap: "round",
      strokeLinejoin: "round",
    },
  }),
}));

type Props = SvgIconProps & { strokeColor?: React.CSSProperties["color"] };

function Liga40Logo({ strokeColor, ...props }: Props) {
  const classes = useStyles({ color: strokeColor });

  return (
    <SvgIcon className={classes.root} {...props} viewBox="0 0 453.83 641.47">
      <path
        className="a"
        d="M230,351.9l-27.31-52H141.25v52h-22V235.41h99a59.78,59.78,0,0,1,16.88,2.07,30.92,30.92,0,0,1,11.33,5.79,21.79,21.79,0,0,1,6.37,8.85,31.29,31.29,0,0,1,2,11.42v8.27q0,11.26-6.62,18.78t-22.17,9l27.47,52.29Zm2.81-88.53q0-10.76-14.56-10.75H141.25v30.12H218.2q14.56,0,14.56-10.76Z"
        transform="translate(-65.38 -38.37)"
      />
      <path className="a" d="M273.3,351.9V235.41h22V351.9Z" transform="translate(-65.38 -38.37)" />
      <path
        className="a"
        d="M353.39,351.9a59.42,59.42,0,0,1-16.88-2.07A30.62,30.62,0,0,1,325.18,344a21.7,21.7,0,0,1-6.37-8.85,31.25,31.25,0,0,1-2-11.42V263.54a31.29,31.29,0,0,1,2-11.42,21.7,21.7,0,0,1,6.37-8.85,30.92,30.92,0,0,1,11.33-5.79,59.78,59.78,0,0,1,16.88-2.07h76.95a59.78,59.78,0,0,1,16.88,2.07,30.92,30.92,0,0,1,11.33,5.79,21.79,21.79,0,0,1,6.37,8.85,31.29,31.29,0,0,1,2,11.42v60.23a31.25,31.25,0,0,1-2,11.42,21.79,21.79,0,0,1-6.37,8.85,30.62,30.62,0,0,1-11.33,5.79,59.42,59.42,0,0,1-16.88,2.07Zm91.51-88.53q0-10.76-14.56-10.75H353.39q-14.56,0-14.56,10.75v60.57q0,10.76,14.56,10.76h76.95q14.55,0,14.56-10.76Z"
        transform="translate(-65.38 -38.37)"
      />
      <path
        className="a"
        d="M246,490.59V470h-109V455.56l63.88-85.86h24.73l-61.48,82.42H246V369.7h22.84v82.42h12.71V470H268.81v20.61Z"
        transform="translate(-65.38 -38.37)"
      />
      <path
        className="a"
        d="M333.72,490.59a62.1,62.1,0,0,1-17.52-2.15,31.93,31.93,0,0,1-11.76-6,22.67,22.67,0,0,1-6.61-9.19,32.6,32.6,0,0,1-2.06-11.85v-62.5A32.67,32.67,0,0,1,297.83,387a22.64,22.64,0,0,1,6.61-9.18,32.09,32.09,0,0,1,11.76-6,62.5,62.5,0,0,1,17.52-2.14h56.84a62.48,62.48,0,0,1,17.51,2.14,32.09,32.09,0,0,1,11.76,6,22.64,22.64,0,0,1,6.61,9.18,32.46,32.46,0,0,1,2.06,11.85v62.5a32.39,32.39,0,0,1-2.06,11.85,22.67,22.67,0,0,1-6.61,9.19,31.93,31.93,0,0,1-11.76,6,62.08,62.08,0,0,1-17.51,2.15Zm71.95-91.87q0-11.16-15.11-11.16H333.72q-15.12,0-15.11,11.16v62.85q0,11.16,15.11,11.16h56.84q15.1,0,15.11-11.16Z"
        transform="translate(-65.38 -38.37)"
      />
      <path
        className="a"
        d="M440.77,391.66c-2.42,0-4.13-.47-5.16-1.42a4.86,4.86,0,0,1-1.53-3.73v-11a4.78,4.78,0,0,1,1.53-3.7c1-.93,2.74-1.39,5.16-1.39h14q3.61,0,5.15,1.39a4.78,4.78,0,0,1,1.53,3.7v11a4.86,4.86,0,0,1-1.53,3.73q-1.53,1.42-5.15,1.42Zm16.65-16.11c0-1.31-.89-2-2.68-2h-14c-1.8,0-2.69.66-2.69,2v11c0,1.31.89,2,2.69,2h14c1.79,0,2.68-.66,2.68-2Z"
        transform="translate(-65.38 -38.37)"
      />
      <path
        className="b"
        d="M510,51.28,476.24,68.1a60.28,60.28,0,0,1-26.91,6.34H405.17a31.45,31.45,0,0,1-62.52,0H241.93a31.45,31.45,0,0,1-62.52,0H136.57a66,66,0,0,1-29.41-6.93L74.63,51.28l-1.25,351C84.15,512.45,179.32,598.52,292.29,598.52S500.43,512.45,511.2,402.3Z"
        transform="translate(-65.38 -38.37)"
      />
      <path
        className="c"
        d="M425.27,102.44a66.38,66.38,0,0,1-102.58,0h-60.8a66.38,66.38,0,0,1-102.58,0H126.93a26.93,26.93,0,0,1-9.68-1.8L97.89,93.18V395.27c9.62,98.37,93.5,175.25,194.4,175.25s184.78-76.88,194.4-175.25V93.18l-18.82,7.15a32.21,32.21,0,0,1-11.49,2.11Z"
        transform="translate(-65.38 -38.37)"
      />
      <path
        className="a"
        d="M158.59,178.2c.47-2.2,1-3.63,1.57-4.27a3.42,3.42,0,0,1,2.71-1,2.66,2.66,0,0,1,2.22.81,3,3,0,0,1,.25,2.54l-.4,1.89-3.09,14.51a4,4,0,0,1-1.32,2.4A3.7,3.7,0,0,1,158,196H116.83a2.68,2.68,0,0,1-2.22-.79,2.72,2.72,0,0,1-.29-2.38,3.37,3.37,0,0,1,1.43-2.41,10.1,10.1,0,0,1,4.43-.64h5.51L134.16,150h-5.51c-2.18,0-3.54-.22-4.1-.68s-.72-1.28-.49-2.49a4,4,0,0,1,1.35-2.4,4.26,4.26,0,0,1,2.71-.78H152a2.81,2.81,0,0,1,2.25.8,2.68,2.68,0,0,1,.3,2.38,3.43,3.43,0,0,1-1.5,2.49c-.76.46-2.26.68-4.49.68h-7.41l-8.47,39.73h23.46Z"
        transform="translate(-65.38 -38.37)"
      />
      <path
        className="a"
        d="M254.06,189.75c2.26,0,3.66.22,4.21.64s.7,1.23.46,2.41a3.72,3.72,0,0,1-1.48,2.49c-.72.46-2.21.68-4.47.68H223.94a2.84,2.84,0,0,1-2.27-.79,2.62,2.62,0,0,1-.33-2.38,3.36,3.36,0,0,1,1.45-2.41,10.44,10.44,0,0,1,4.5-.64h10L245.72,150h-10c-2.21,0-3.6-.22-4.17-.68s-.74-1.28-.51-2.49a3.91,3.91,0,0,1,1.37-2.4,4.3,4.3,0,0,1,2.69-.78H266a2.72,2.72,0,0,1,2.23.8,2.75,2.75,0,0,1,.29,2.38,3.49,3.49,0,0,1-1.5,2.49c-.77.46-2.25.68-4.46.68h-10l-8.47,39.73Z"
        transform="translate(-65.38 -38.37)"
      />
      <path
        className="a"
        d="M356.14,188.39l2.25-10.5H347.45a2.11,2.11,0,0,1-1.83-.7,2.7,2.7,0,0,1-.19-2.16,4.47,4.47,0,0,1,1.07-2.29,3.41,3.41,0,0,1,2.32-.62h19.05a1.6,1.6,0,0,1,1.54.72,3.23,3.23,0,0,1,.09,2.19,3.39,3.39,0,0,1-1.17,2.25,4.7,4.7,0,0,1-2.71.61h-.57l-2.6,12.22-.22,1.06a4.28,4.28,0,0,1-.64,1.65,3.86,3.86,0,0,1-1.26.91,30.42,30.42,0,0,1-7.85,2.55,48,48,0,0,1-10,1.06q-11.81,0-17.48-7.54t-3.11-19.67a36.52,36.52,0,0,1,11.4-20,28.9,28.9,0,0,1,19.82-7.89,25.53,25.53,0,0,1,5.87.68,31.43,31.43,0,0,1,5.82,2,4.68,4.68,0,0,1,1.59-1.65,4.12,4.12,0,0,1,2.11-.51c1.38,0,2.21.41,2.49,1.24s.1,2.72-.55,5.69l-1.23,6a5.94,5.94,0,0,1-1.41,3,3.94,3.94,0,0,1-2.78.86c-1.53,0-2.44-.94-2.74-2.82a9.06,9.06,0,0,0-.26-1.28,9.38,9.38,0,0,0-3.55-5.38,10.91,10.91,0,0,0-6.55-1.89,19.28,19.28,0,0,0-13.82,5.71q-5.94,5.7-8,15.41-2.12,10,1.83,15.92t12.59,5.91a23.23,23.23,0,0,0,5.32-.66A42.25,42.25,0,0,0,356.14,188.39Z"
        transform="translate(-65.38 -38.37)"
      />
      <path
        className="a"
        d="M444.6,150h-6.44a6.32,6.32,0,0,1-3.62-.72,2.29,2.29,0,0,1-.57-2.45,3.62,3.62,0,0,1,1.5-2.49q1.09-.69,5.33-.69h12.57c1.62,0,2.66.22,3.13.64a4.44,4.44,0,0,1,1.06,2.36l8.07,43.08h.75a5.05,5.05,0,0,1,3.13.67c.5.44.63,1.23.4,2.38a4,4,0,0,1-1.37,2.49,5.84,5.84,0,0,1-3.26.68h-12c-2.23,0-3.62-.22-4.16-.68s-.69-1.29-.42-2.49a3.32,3.32,0,0,1,1.52-2.36,8.47,8.47,0,0,1,4-.69h4.11l-2-10.49h-22.4l-6,10.49h4.28q2.91,0,3.84.69a2.15,2.15,0,0,1,.57,2.36,3.72,3.72,0,0,1-1.48,2.49c-.72.46-2.15.68-4.3.68H419.2c-1.89,0-3.09-.22-3.62-.68s-.66-1.29-.4-2.49a3.48,3.48,0,0,1,1.41-2.38,7.5,7.5,0,0,1,3.71-.67h.84Zm-7.24,23.38h18L450.9,150Z"
        transform="translate(-65.38 -38.37)"
      />
      <rect className="a" x="49.02" y="169.18" width="356.85" height="4.19" rx="0.9" ry="0.9" />
      <path
        className="a"
        d="M136.44,600.15l9.42,6.34-4.47,6.64L132,606.78,119.05,626l-7.3-4.91L143,574.6l19.31,13-4.47,6.63-12-8.09Z"
        transform="translate(-65.38 -38.37)"
      />
      <path
        className="a"
        d="M167.34,590.25l7.69,4.28L147.8,643.47l-7.69-4.28Z"
        transform="translate(-65.38 -38.37)"
      />
      <path
        className="a"
        d="M182.6,615.56l-16.08,37.23-7.27-3.14,22.2-51.41,10.14,4.38-5,34.36,13.3-30.78,7.19,3.11-22.2,51.41-8.3-3.58Z"
        transform="translate(-65.38 -38.37)"
      />
      <path
        className="a"
        d="M219.41,638.91l11.87,3.33-4.81,17.1c-2.42,8.63-8.12,12.34-16.44,10s-11.25-8.48-8.82-17.11l7.87-28c2.43-8.62,8.13-12.34,16.44-10s11.25,8.48,8.83,17.1l-1.47,5.24-8-2.25,1.62-5.78c1.08-3.85-.2-5.79-2.9-6.55s-4.8.24-5.88,4.09l-8.18,29.11c-1.08,3.85.22,5.72,2.92,6.47s4.78-.15,5.86-4l2.79-9.94-3.85-1.08Z"
        transform="translate(-65.38 -38.37)"
      />
      <path
        className="a"
        d="M249.35,644.17l12,1.73-1.15,7.92-11.95-1.73-2.36,16.23,15,2.18-1.15,7.92L236,675,244,619.55,267.78,623l-1.15,7.92-15-2.18Z"
        transform="translate(-65.38 -38.37)"
      />
      <path
        className="a"
        d="M291.06,679.69c-.46-1.45-.76-2.33-.68-6.89l.15-8.8c.08-5.2-1.64-7.15-5.64-7.21l-3-.06-.39,22.8-8.8-.15.95-56,13.28.23c9.12.15,13,4.46,12.82,13.09l-.07,4.4c-.1,5.76-2,9.49-6,11.26,4.37,1.92,5.74,6.18,5.64,12l-.15,8.64c0,2.72,0,4.72.85,6.81Zm-8.78-48.15-.3,17.2,3.44,0c3.28.06,5.31-1.35,5.38-5.83l.1-5.51c.07-4-1.26-5.79-4.38-5.84Z"
        transform="translate(-65.38 -38.37)"
      />
      <path
        className="a"
        d="M333.89,633.06l.23,2c.68,5.72-.63,9.58-4.31,11.87,4.91,1.27,7.23,5.26,7.93,11.14l.54,4.53c1,8.58-3,13.72-11.7,14.76L312.84,679l-6.61-55.61,13.19-1.56C328.48,620.73,332.87,624.48,333.89,633.06Zm-18-2.78,1.74,14.7,3.42-.41c3.25-.38,5.07-2.05,4.54-6.5l-.37-3.1c-.47-4-2-5.56-5.12-5.19Zm2.68,22.64,2,17.08,5-.59c2.93-.35,4.36-1.89,3.87-6l-.57-4.85c-.62-5.16-2.47-6.55-6.44-6.08Z"
        transform="translate(-65.38 -38.37)"
      />
      <path
        className="a"
        d="M341.46,632.24c-2.21-8.68,1.1-14.81,9.48-16.94s14.21,1.67,16.42,10.35l7.18,28.22c2.21,8.68-1.1,14.81-9.48,16.94s-14.21-1.67-16.41-10.35Zm15.85,26.59c1,3.88,3.07,4.92,5.78,4.23s4.05-2.6,3.06-6.48l-7.46-29.3c-1-3.88-3.06-4.92-5.78-4.23s-4,2.6-3.06,6.48Z"
        transform="translate(-65.38 -38.37)"
      />
      <path
        className="a"
        d="M417.06,652.05l-8.23,3.32-5.21-8.85-10,4,2.39,10-7.5,3-12.61-55.29,11.95-4.81Zm-25.26-9,7.87-3.16-14.52-24.69Z"
        transform="translate(-65.38 -38.37)"
      />
      <path
        className="a"
        d="M443.8,637.54c-1.13-1-1.84-1.62-4.1-5.58l-4.36-7.64c-2.58-4.52-5.06-5.32-8.53-3.33l-2.64,1.5,11.3,19.81-7.65,4.36L400.07,598l11.54-6.58c7.92-4.52,13.42-2.78,17.71,4.72L431.5,600c2.85,5,3.12,9.18.62,12.72,4.74-.58,8.09,2.39,11,7.46l4.28,7.51c1.35,2.36,2.41,4.06,4.21,5.43ZM411.68,600.6l8.52,14.94,3-1.7c2.85-1.63,3.87-3.87,1.65-7.76l-2.73-4.79c-2-3.48-4-4.33-6.75-2.79Z"
        transform="translate(-65.38 -38.37)"
      />
      <path
        className="a"
        d="M428.08,581.53l11.05-8.46c7-5.35,13.39-4.1,18.83,3l17.21,22.49c5.45,7.12,5,13.62-2,19L462.11,626Zm11.85,1,24.31,31.77,3.94-3c2.22-1.7,2.68-3.87.25-7l-17.7-23.12c-2.43-3.18-4.64-3.3-6.86-1.6Z"
        transform="translate(-65.38 -38.37)"
      />
      <path
        className="d"
        d="M221.3,507.73l18.93,11a20,20,0,0,0,10.08,2.73H256l34.85-1.3c2.61-.1,5.23-.09,7.84,0l29.95,1.28h5.66a20,20,0,0,0,10.08-2.73l18.93-11"
        transform="translate(-65.38 -38.37)"
      />
      <circle className="d" cx="188.63" cy="497.04" r="5.71" />
      <circle className="d" cx="265.2" cy="497.04" r="5.71" />
    </SvgIcon>
  );
}

export default Liga40Logo;

import * as React from "react";
import useAuth from "../../core/hooks/useAuth";
import { PermissionsType } from "../../core/models/Permission";
import { isArrayValid } from "../../helpers/utility";

type ProtectedRenderProps = {
  permission: PermissionsType | PermissionsType[];
  children?: React.ReactNode;
};

function ProtectedRender({ permission, children }: ProtectedRenderProps) {
  const authProvider = useAuth();
  let hasPermission = false;
  let blockedByScreenSize = false;

  if (isArrayValid(permission)) {
    hasPermission = authProvider.hasAnyPermission(permission as PermissionsType[]);
    blockedByScreenSize = authProvider.mobileRestriction(permission as PermissionsType[]);
  } else {
    hasPermission = authProvider.hasPermission(permission as PermissionsType);
    blockedByScreenSize = authProvider.mobileRestriction([permission] as PermissionsType[]);
  }

  if (!hasPermission || blockedByScreenSize) {
    return null;
  }

  return <> {children} </>;
}

export default ProtectedRender;

import IPermission from "../contracts/IPermission";

export const DEFAULT_PERMISSION_LEVEL = 10;

// Front restricted → access to the landing page;
export const PERMISSION_ACCESS_BASE = 0;

export const PERMISSION_ACCESS_ADMIN = 1;
export const PERMISSION_ACCESS_LEVEL_CHART = 2;
export const PERMISSION_ACCESS_CONSUMPTION_CHART = 3;
export const PERMISSION_ACCESS_LEVEL_REPORT = 4;
export const PERMISSION_ACCESS_CONSUMPTION_REPORT = 5;
export const PERMISSION_ACCESS_INFORM_LEVEL = 6;
export const PERMISSION_ACCESS_CONSUMPTION_HISTORY = 7;
export const PERMISSION_ACCESS_LEVEL_HISTORY = 8;

export const APP_PERMISSIONS = [
  PERMISSION_ACCESS_BASE,
  PERMISSION_ACCESS_ADMIN,
  PERMISSION_ACCESS_LEVEL_CHART,
  PERMISSION_ACCESS_CONSUMPTION_CHART,
  PERMISSION_ACCESS_LEVEL_REPORT,
  PERMISSION_ACCESS_CONSUMPTION_REPORT,
  PERMISSION_ACCESS_INFORM_LEVEL,
  PERMISSION_ACCESS_CONSUMPTION_HISTORY,
  PERMISSION_ACCESS_LEVEL_HISTORY,
] as const;

export type PermissionsType = typeof APP_PERMISSIONS[number];

type Permission = Omit<IPermission, "type"> & { type: PermissionsType };

export default Permission;

export function getPermissionFromEnum(rule: PermissionsType): string {
  switch (rule) {
    case PERMISSION_ACCESS_BASE:
      return "Básico";

    case PERMISSION_ACCESS_ADMIN:
      return "Admin";

    case PERMISSION_ACCESS_LEVEL_CHART:
      return "Gráfico de Nível";

    case PERMISSION_ACCESS_CONSUMPTION_CHART:
      return "Gráfico de Consumo";

    case PERMISSION_ACCESS_LEVEL_REPORT:
      return "Relatório de Nível";

    case PERMISSION_ACCESS_CONSUMPTION_REPORT:
      return "Relatório de Consumo";

    case PERMISSION_ACCESS_INFORM_LEVEL:
      return "Informar Nível";

    case PERMISSION_ACCESS_CONSUMPTION_HISTORY:
      return "Histórico de Consumo";

    case PERMISSION_ACCESS_LEVEL_HISTORY:
      return "Histórico de Nível";

    default:
      // eslint-disable-next-line no-console
      console.error("getNotificationRuleFromEnum → rule not valid! Returning empty!");
      return "";
  }
}

export const PERMISSION_LEVEL_READ = 10;
export const PERMISSION_LEVEL_EDIT = 20;
export const PERMISSION_LEVEL_INCLUDE = 30;
export const PERMISSION_LEVEL_DELETE = 40;
export const PERMISSION_LEVEL_DOWNLOAD = 50;

export const PERMISSION_LEVELS = [
  PERMISSION_LEVEL_READ,
  PERMISSION_LEVEL_EDIT,
  PERMISSION_LEVEL_INCLUDE,
  PERMISSION_LEVEL_DELETE,
  PERMISSION_LEVEL_DOWNLOAD,
] as const;

export type PERMISSION_LEVELS_TYPE = typeof PERMISSION_LEVELS[number];

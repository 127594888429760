import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Hubx from "./HubX";
import Fedx from "./Fedx";
import logOverwrite from "./helpers/log";
import "./style.css";

/** *** GLOBAL CONFIGURATION *** **/

/** * HUBX CONFIGURATION * **/
window.Hubx = new Hubx();
new Fedx().build(window.Hubx);

/* Log Config */
logOverwrite();

ReactDOM.render(<>{<App />}</>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();
